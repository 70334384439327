import React from "react";
import Layout from "../components/Layout";
import NewsletterInput from "../components/NewsletterInput";
import VideoPlayer from '../components/VideoPlayer';

import "../style/tg100-kids-style.sass";

import TG100KidsLogo from "../img/TG_kids.png";
import wave from "../img/wave.png";
import point from "../img/point.png";
import BookCover from "../img/BookCover.png";
import BookPromo from '../assets/book_promo.mp4';
import BookThumbnail from '../img/video_thumbnail_book.png';

const TG100Kids = () => {
  return (
    <Layout>
      <div className="container blue-bg">
        <div className="section over">
          <img src={TG100KidsLogo} width="50%" alt="TG100 Kids logo" />
          <img src={wave} className="wave" alt="Cartoon girl smiling and waving" />
        </div>
      </div>
      <div className="container green-bg">
        <div className="section under">
          <img src={point} className="point" alt="Cartoon boy pointing"/>
          <div className="newsletter-kids">
            <h2>coming soon</h2>
            <h3>sign-up below for TG100 KIDS updates</h3>
            <div>*Stay up to date with TG100 KIDS newsletters</div>
            <NewsletterInput justifyContent="flex-end" />
          </div>
        </div>
      </div>
      <div className="container blue-bg">
        <div className="section book">
          <div className='book-img'>
            <img src={BookCover} width='100%' alt="Cover of book titled A Visit to Arthur Ashe Stadium"></img>
          </div>
          <div className='book-desc'>
            <h2>A Visit to Arthur Ashe Stadium</h2>
            <h3>TG100 Kids children's book coming soon!</h3>
            <p>Eli&apos;s Tennis Adventures; Tennis Unites Us, follows Eli and his friends as they are magically transported from their tennis academy to Arthur Ashe Stadium in New York. Here, they learn all about facing adversity and becoming kinder, more inclusive kids who share a love for the game of tennis!</p>
          </div>
        </div>
      </div>
      <VideoPlayer videoSrc={BookPromo} thumbnailSrc={BookThumbnail} />
    </Layout>
  );
}

export default TG100Kids;
